import './BookAppointmentsNotPublished.less'

import { Button } from 'antd'
import { navigate } from 'gatsby-plugin-react-intl'
import React from 'react'

import { useScopedIntl } from '../../../../hooks'
import { routes } from '../../../../routes'
import { DatacIcon, DatacTitle } from '../../../common'

export const BookAppointmentsNotPublished: React.VFC = () => {
  const intlNotPublished = useScopedIntl('subject_dashboard.appointment.book.not_published')

  return (
    <div className="subject-dashboard-book-appointment-not-published">
      <DatacIcon name="check" type="red" />
      <DatacTitle>{intlNotPublished('title')}</DatacTitle>
      <div>{intlNotPublished('description')}</div>
      <Button type="default" onClick={() => navigate(routes.subjectDashboard)}>
        {intlNotPublished('homepage')}
      </Button>
    </div>
  )
}
