import './BookAppointmentsAlreadyBooked.less'

import { Button } from 'antd'
import { navigate } from 'gatsby-plugin-react-intl'
import React from 'react'

import { useScopedIntl } from '../../../../hooks'
import { routes } from '../../../../routes'
import { DatacIcon, DatacTitle } from '../../../common'

export const BookAppointmentsAlreadyBooked: React.VFC = () => {
  const intlAlreadyBooked = useScopedIntl('subject_dashboard.appointment.book.already_booked')

  return (
    <div className="subject-dashboard-book-appointment-already-booked">
      <DatacIcon name="check" type="blue" />
      <DatacTitle>{intlAlreadyBooked('title')}</DatacTitle>
      <div>{intlAlreadyBooked('description')}</div>
      <Button type="default" onClick={() => navigate(routes.subjectDashboard)}>
        {intlAlreadyBooked('homepage')}
      </Button>
    </div>
  )
}
