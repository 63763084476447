import './BookAppointmentsNotAvailable.less'

import { Button } from 'antd'
import { navigate } from 'gatsby-plugin-react-intl'
import React from 'react'

import { useScopedIntl } from '../../../../hooks'
import { routes } from '../../../../routes'
import { DatacIcon, DatacTitle } from '../../../common'

interface BookAppointmentsNotAvailableProps {
  goBackToSchedule: () => void
}
export const BookAppointmentsNotAvailable: React.VFC<BookAppointmentsNotAvailableProps> = ({ goBackToSchedule }) => {
  const intlNotAvailable = useScopedIntl('subject_dashboard.appointment.book.not_available')

  return (
    <div className="subject-dashboard-book-appointment-not-available">
      <DatacIcon name="check" type="red" />
      <DatacTitle>{intlNotAvailable('title')}</DatacTitle>
      <div>{intlNotAvailable('description')}</div>
      <Button type="primary" onClick={() => navigate(routes.subjectDashboard)}>
        {intlNotAvailable('accept')}
      </Button>
      <Button type="default" onClick={goBackToSchedule}>
        {intlNotAvailable('cancel')}
      </Button>
    </div>
  )
}
